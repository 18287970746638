var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',_vm._g(_vm._b({ref:"observer",attrs:{"tag":"form","action":_vm.action,"enctype":_vm.formEncType,"method":_vm.method,"name":_vm.name},on:{"submit":_vm.onSubmit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var dirty = ref.dirty;
    var pristine = ref.pristine;
    var valid = ref.valid;
    var invalid = ref.invalid;
    var pending = ref.pending;
    var touched = ref.touched;
    var untouched = ref.untouched;
    var errors = ref.errors;
    var validate = ref.validate;
    var reset = ref.reset;
    var handleSubmit = ref.handleSubmit;
return [_vm._t("default",null,{"dirty":dirty,"errors":errors,"invalid":invalid,"pending":pending,"pristine":pristine,"reset":reset,"touched":touched,"untouched":untouched,"valid":valid,"validate":validate,"handleSubmit":handleSubmit})]}}],null,true)},'validation-observer',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }